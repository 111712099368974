import { API_VERSION, PUBLISHER_ID } from "../shared/common/constant";

/* Microsoft SaaS Fulfillment APIs
RESOLVE
  - POST 
  - https://marketplaceapi.microsoft.com/api/saas/subscriptions/resolve?api-version=<ApiVersion>
  - Header -> authorization, x-ms-marketplace-token
ACTIVATE
  - POST
  - https://marketplaceapi.microsoft.com/api/saas/subscriptions/<subscriptionId>/activate?api-version=<ApiVersion>
  - Header -> authorization
GET LIST OF ALL SUBSCRIPTIONS
  - GET
  - https://marketplaceapi.microsoft.com/api/saas/subscriptions?api-version=<ApiVersion>
  - Header -> authorization
CHANGE PLAN
  - PATCH
  - https://marketplaceapi.microsoft.com/api/saas/subscriptions/<subscriptionId>?api-version=<ApiVersion>
  - Header -> authorization
  - Payload -> {
  "planId": "gold" // the ID of the new plan to be purchased
}
  - Response Code -> 202
CANCEL SUBSCRIPTION
 - DELETE
 - https://marketplaceapi.microsoft.com/api/saas/subscriptions/<subscriptionId>?api-version=<ApiVersion>
 - Response Code -> 202
  */

/* Resolve subscription 
POST
https://marketplaceapi.microsoft.com/api/saas/subscriptions/resolve?api-version=${API_VERSION}
*/

export async function resolveSubscription() {
  // TODO: Remove sample marketplaceToken
  const marketplaceToken = `ewogICJpZCI6ICI0YTk5NTFhMy0yOGM3LTQ4MDEtYjliZi0wN2MzNWZjZWIzNjAiLAogICJuYW1lIjogIlN1YnNjcmlwdGlvbiAyIiwKICAib2ZmZXJJZCI6ICJmbGF0LXJhdGUiLAogICJwbGFuSWQiOiAiZmxhdC1yYXRlLTEiLAogICJiZW5lZmljaWFyeSI6IHsKICAgICJlbWFpbElkIjogInVzZXJAZm9ydGhjb2ZmZWUuY29tIiwKICAgICJvYmplY3RJZCI6ICJjNzVlMjEwZS0zNThlLTRiODItODIxMS01MjE3MjE1ZGI0NDAiLAogICAgInRlbmFudElkIjogImYyYmEyZmY4LWQ5MDAtNDkwMC05ZWIwLWRhYjkzNGU3NTkxNSIKICB9LAogICJwdXJjaGFzZXIiOiB7CiAgICAiZW1haWxJZCI6ICJ1c2VyQGZvcnRoY29mZmVlLmNvbSIsCiAgICAib2JqZWN0SWQiOiAiYzc1ZTIxMGUtMzU4ZS00YjgyLTgyMTEtNTIxNzIxNWRiNDQwIiwKICAgICJ0ZW5hbnRJZCI6ICJmMmJhMmZmOC1kOTAwLTQ5MDAtOWViMC1kYWI5MzRlNzU5MTUiCiAgfSwKICAicXVhbnRpdHkiOiBudWxsLAogICJhdXRvUmVuZXciOiBmYWxzZSwKICAiaXNUZXN0IjogZmFsc2UsCiAgImlzRnJlZVRyaWFsIjogZmFsc2UKfQ==`;
  // TODO: Replace API 
  // localhost:3978 -> where the emulator port is running
  const resolveResponse = await fetch(
    `http://localhost:3978/api/saas/subscriptions/resolve?publisherId=${PUBLISHER_ID}&api-version=${API_VERSION}`,
    {
      method: "POST",
      headers: {
        //'Authorization': `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "x-ms-marketplace-token": `${marketplaceToken}`,
      },
    }
  );

  if (resolveResponse.ok) {
    console.log("Subscription resolved");
  } else {
    console.error(
      "Error resolving subscription:",
      await resolveResponse.json()
    );
  }
  return null;
}

/* Activate subscription 
POST  
https://marketplaceapi.microsoft.com/api/saas/subscriptions/<subscriptionId>/activate?api-version=<ApiVersion>
*/
export async function activateSubscription() {
  const planId = "flat-rate-1";
  const activateResponse = await fetch(
    `http://localhost:3978/api/saas/subscriptions/:subscriptionId/activate?publisherId=${PUBLISHER_ID}&api-version=${API_VERSION}`,
    {
      method: "POST",
      headers: {
        //'Authorization': `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        planId: `${planId}`,
      }),
    }
  );

  if (activateResponse.ok) {
    console.log("Subscription activated");
  } else {
    console.error(
      "Error activating subscription:",
      await activateResponse.json()
    );
  }
  return null;
}

/* List available subscriptions */
export async function listAvailableSubscription() {
  const availableSubsResponse = await fetch(
    `http://localhost:3978/api/saas/subscriptions/?publisherId=${PUBLISHER_ID}&api-version=${API_VERSION}`,
    {
      method: "GET",
    }
  );
  const response = await availableSubsResponse.json();
  return response;
}
//TO DO: Remove
const SUBSCRIPTION_ID = "4bf9130e-5dce-4067-b971-5841411f7ab4";

/* List available plans
GET
https://marketplaceapi.microsoft.com/api/saas/subscriptions/<subscriptionId>/listAvailablePlans?api-version=<ApiVersion>&planId=<planId>
 */
export async function listAvailablePlan() {
  const availablePlansResponse = await fetch(
    `http://localhost:3978/api/saas/subscriptions/${SUBSCRIPTION_ID}/listAvailablePlans/?publisherId=${PUBLISHER_ID}&api-version=${API_VERSION}`,
    {
      method: "GET",
    }
  );
  const response = await availablePlansResponse.json();
  return response;
}

/* Change plan 
PATCH
https://marketplaceapi.microsoft.com/api/saas/subscriptions/${SUBSCRIPTION_ID}?api-version=${API_VERSION}
*/
export async function changePlan() {
  const planId = "flat-rate-1";
  const changeResponse = await fetch(
    `http://localhost:3978/api/saas/subscription/${SUBSCRIPTION_ID}/`,
    {
      method: "PATCH",
      headers: {
        //'Authorization': `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        planId: `${planId}`,
      }),
    }
  );
}

/* Cancel subscription 
DELETE
https://marketplaceapi.microsoft.com/api/saas/subscriptions/${SUBSCRIPTION_ID}?api-version=${API_VERSION}
*/
export async function cancelSubscription() {
  const cancelResponse = await fetch(
    `http://localhost:3978/api/saas/subscription/${SUBSCRIPTION_ID}/`,
    {
      method: "DELETE",
      headers: {
        //'Authorization': `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
}
