/** License Management page allows the user to see
 * the list of users and manage licenses for their tenant
 * ENDPOINT -> /licenses */

import React from "react";
import WelcomeName from "../components/WelcomeName";
import { useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { loginRequest } from "../services/authProvider";
import {
  addAppRoleAdmin,
  addAppRoleUser,
  callMsGraph,
  getUserAppRoles,
  removeAppRole,
} from "../services/msGraphApiCall";
import { callUsers } from "../services/msGraphApiCall";
import "./css/LicenseManagement.css";
import "../App.css";
import { AppDefaults, CurrentRole } from "../shared/common/constant";
import { ACTION_HANDLER_TYPE } from "../shared/common/constant";
import ActionHandler from "../services/data-handler";
import Table from "../components/Table";

const LicenseManagement = () => {
  const [users, setUsers] = React.useState(null);
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = React.useState(null);

  const columns = React.useMemo(
    () => [
      {
        Header: "Users",
        accessor: (props) => {
          return (
            <div className="flex">
              {" "}
              <div className="relative inline-flex items-center justify-center ml-2 w-10 h-10 overflow-hidden bg-[#333] rounded-full">
                <span className="font-medium text-gray-100">
                  {setProfilePicture(props)}{" "}
                </span>{" "}
              </div>
              <div className="w-72 lg:w-36 xl:w-28">
                <div className="p-2 ml-3">{props.userPrincipalName}</div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Role",
        accessor: (props) => {
          return (
            <select
              //disabled={item.displayName === graphData.displayName ? true : false}
              defaultValue={props.role}
              id={getRoleId(props.id)}
              onChange={() => assignRole(props.id)}
            >
              <option disabled={true}>Select</option>
              <option value="User">User</option>
              <option value="Admin">Admin</option>
            </select>
          );
        },
      },
      {
        Header: "Notify By",
        accessor: (props) => {
          return (
            <select defaultValue={"email"} id={getNotifyById(props.id)}>
              <option disabled={true}>Select</option>
              <option id="email" value="email">
                email
              </option>
              {/* <option id="teamsChat" value="teamsChat">Teams chat</option>
                  <option id="teamsChatEmail" value="teamsChatEmail">Teams chat & email</option> */}
            </select>
          );
        },
      },
      {
        Header: "License",
        accessor: (props) => {
          return (
            <label className="switch">
              <input
                type="checkbox"
                onChange={(event) =>
                  licenseUpdateNotify(props, event.target.checked)
                }
              />
              <span className="slider round"></span>
            </label>
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  const setProfilePicture = (user) => {
    if (user) {
      var name = user.givenName;
      var lastName = user.surname;
      var displayName = user.displayName;
      var initials = "";
      if (name) {
        initials = name.charAt(0);
      }
      if (lastName) {
        initials = initials + lastName.charAt(0);
      } else if (displayName) {
        var display = displayName.split(" ");
        initials = display[0].charAt(0) + display[1].charAt(0);
      }
      return initials;
    }
  };

  const search = (event) => {
    const { value } = event.target;
    callUsers(null, value)
      .then((response) => {
        const list = response.value.filter(
          (user) => user.userPrincipalName !== null
        );
        displayRole(list);
      })
      .catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount(),
          });
        }
      });
  };

  /* assignAdmin - assigns the user in the list the approle of Admin
   id - the user's microsoft id */
  const assignAdmin = async (id) => {
    console.log("admin");
    await getAndRemoveRole(id);
    addAppRoleAdmin(null, id).then(() => {
      getUserAppRoles(null, id).then((response1) => {
        console.log("after delete and adding", response1);
      });
    });
  };

  /* assignUser - assign the user in the list the approle of User
   id - the user's microsoft id */
  const assignUser = async (id) => {
    console.log("user");
    await getAndRemoveRole(id);
    addAppRoleUser(null, id).then(() => {
      getUserAppRoles(null, id).then((response1) => {
        console.log("after delete and adding", response1);
      });
    });
  };

  /* getAndRemoveRole - get the user's available approles and removes the old role
   id - the user's microsoft id */
  const getAndRemoveRole = async (id) => {
    const response = await getUserAppRoles(null, id);
    console.log(response);
    const resourceid = response.value;
    for (let x = 0; x < resourceid.length; x++) {
      if (
        resourceid[x].resourceDisplayName === AppDefaults.ResourceDisplayName &&
        resourceid[x].appRoleId !== AppDefaults.DefaultAppRole
      ) {
        await removeRole(resourceid[x].id);
      }
    }
  };

  /* getRole - gets what the user's role to display in the list
   id - the user's microsoft id */
  const getRole = async (id) => {
    const response = await getUserAppRoles(null, id);
    const resourceid = response.value;
    for (let x = 0; x < resourceid.length; x++) {
      if (
        resourceid[x].resourceDisplayName === AppDefaults.ResourceDisplayName &&
        resourceid[x].appRoleId === CurrentRole.ADMIN
      ) {
        console.log("role admin");
        return "Admin";
      } else if (
        resourceid[x].resourceDisplayName === AppDefaults.ResourceDisplayName &&
        resourceid[x].appRoleId === CurrentRole.User
      ) {
        console.log("role user");
        return "User";
      }
    }
    return "User";
  };

  const removeRole = async (id) => {
    await removeAppRole(null, id);
  };

  const getRoleId = (id) => {
    return "role-" + id;
  };

  const getNotifyById = (id) => {
    return "notify-by-" + id;
  };

  /* Function name: assignRole
   Description: Assign a user an approle based what is selected
   @param{id} - the user's microsoft id */
  const assignRole = (id) => {
    const roleSelect = document.getElementById(getRoleId(id)).value;
    //add code for condition of having 0 admins left
    if (roleSelect === "Admin") {
      assignAdmin(id);
    } else if (roleSelect === "User") {
      assignUser(id);
    }
  };

  /* displayRole - displays all the users' approle in the list
   users - list of the users in the tenant */
  const displayRole = React.useCallback(async (users) => {
    let array = [];

    for (let x = 0; x < users.length; x++) {
      const role = await getRole(users[x].id);
      array.push({ role: role, ...users[x] });
    }
    setUsers(array);
  }, []);

  const licenseUpdateNotify = (user, checked) => {
    const notifyBySelect = document.getElementById(
      getNotifyById(user.id)
    ).value;
    if (notifyBySelect === "email" || notifyBySelect === "teamsChatEmail") {
      var emailRequest = {
        email: user.userPrincipalName,
        userName: user.givenName,
        assigned: checked,
      };
      ActionHandler(
        ACTION_HANDLER_TYPE.SEND_EMAIL_LICENSE_UPDATE,
        emailRequest
      ).then(() => {
        console.log("email sent successfuly");
      });
    }
  };

  React.useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }

    if (!users && inProgress === InteractionStatus.None) {
      callUsers()
        .then((response) => {
          displayRole(response.value);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {" "}
      <main>
        <div className="pt-20 pb-10 md:pt-28">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex">
              <WelcomeName viewpage={"HOME"} />
              <p className="mt-1 text-base font-regular text-gray-500 md:mt-0 md:ml-2">
                here you can manage licenses.
              </p>
            </div>
          </div>
        </div>

        <div className="py-11 overflow-x-scroll">
          <div className="px-6 mx-auto h-screen">
            <div className="grid">
              {users && (
                <div className="pr-6 md:pr-2">
                  <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                    Manage Licenses
                  </p>
                  <div className="row justify-end mr-4">
                    <div className="px-6 pt-6  row">
                      <div className="space-y-5 sm:space-y-6 column count-container">
                        <div className="grid gap-5">
                          <div className="border border-gray-200 rounded bg-white">
                            <div className="px-5 py-4">
                              <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                                Assigned Licenses
                              </p>
                              <div className="flex items-center justify-between mt-3">
                                <p className="text-lg font-bold text-gray-900">
                                  0
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="space-y-5 sm:space-y-6 column count-container">
                        <div className="grid gap-5">
                          <div className="border border-gray-200 rounded bg-white">
                            <div className="px-5 py-4">
                              <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                                Available Licenses
                              </p>
                              <div className="flex items-center justify-between mt-3">
                                <p className="text-lg font-bold text-gray-900">
                                  0
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-5 sm:space-y-6 column">
                      <input
                        className="search-field"
                        placeholder="Search by name or email"
                        onChange={search}
                      ></input>
                    </div>
                  </div>
                  <Table columns={columns} data={users} />
                </div>
              )}

              {!users && !inProgress && (
                <tbody>
                  <tr>
                    <td>No user available</td>
                  </tr>
                </tbody>
              )}

              {!users && inProgress && (
                <div className="flex justify-center flex-col m-auto h-auto md:m-auto md:h-96">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#5954A4]"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LicenseManagement;
