export class Pattern {
	// eslint-disable-next-line
	static emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// eslint-disable-next-line
	static passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
	// eslint-disable-next-line
	static numericRegex = /^[0-9]+(\.?[0-9]+)?$/;
	// eslint-disable-next-line
	static nameRegex = /^(?=.*[A-Za-z0-9]).{1,30}$/;
	// eslint-disable-next-line
	static phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
	// eslint-disable-next-line
	static spaceRegex = /^\S+$/g;
	// eslint-disable-next-line
	static urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
	// eslint-disable-next-line
	static domainRegex = /^[a-zA-Z\.]*$/
	// eslint-disable-next-line
	static speicalCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
}

export class FORM {
	static TYPES = {
		URL: 'url',
		TEXT: 'text',
		DATE: 'date',
		EMAIL: 'email',
		NUMBER: 'number',
		BUTTON: 'button',
		SUBMIT: 'submit',
		PASSWORD: 'password',
		CHECKBOX: 'checkbox'
	}
	static ELEMENTS = {
		INPUT: 'input',
		SELECT: 'select',
		BUTTON: 'button',
		INPUT_CHIP: 'input-chip',
		DATE_PICKER: 'datepicker',
		INPUT_CHECKBOX: 'input-checkbox',
		DROPDOWN: 'dropdown'
	}
}

export class ActionTypes {
	// Button Types
	static buttonClick = 'buttonClick';

	// Form Types
	static formSubmit = 'formSubmit';
	static forgotPassword = 'forgotPassword';

	// Modal Types
	static modalClose = 'modalClose';

	// Alert Types
	static successAlert = 'successAlert';
	static failAlert = 'failAlert';
}

export const HTTP_METHODS = {
	GET: 'get',
	POST: 'post',
	PATCH: 'patch',
	DELETE: 'delete'
}

export const UserRole = [
	{
		name: 'Admin',
		id: 1
	},
	{
		name: 'User',
		id: 2
	}
]

export const CurrentRole = {
	ADMIN: '5c401d9a-4be3-45a3-b728-45d5d6d185c7',
	USER: '148b8898-20a5-422e-b888-99c8eb7b99b6'
}

export const AppDefaults = {
	ResourceDisplayName: 'Vodium-Landing-Page',
	DefaultAppRole: '00000000-0000-0000-0000-000000000000',
	ResourceId: 'b5221c28-6df7-40cd-982d-f33865ec3f97'
}

export const StatusKeys = {
	ACTIVATED: "ACTIVATED",
	DEACTIVATED: "DEACTIVATED",
	ARCHIVED: "ARCHIVED",
	PENDING : "PendingFulfillmentStart",
	SUBSCRIBED: "Subscribed"
}

export const alertInterval = 3000;
export const sidebarResponsiveWidth = 60;

export const STORAGE = {
	VODIUM_AUTH: 'VODIUM_AUTH'
}

export const ACTION_HANDLER_TYPE = {
	SEND_EMAIL_CONTACT: 'SEND_EMAIL_CONTACT',
	SEND_EMAIL_BUG: 'SEND_EMAIL_BUG',
	SEND_EMAIL_LICENSE_UPDATE: 'SEND_EMAIL_LICENSE_UPDATE',
	GET_AVAILABLE_SUBSCRIPTION: 'GET_AVAILABLE_SUBSCRIPTION',
}

export const URL_GUIDE = 'https://vodium.com/hubfs/Assets/2024%20User%20Guide.pdf';
export const URL_FEATURES = 'https://vodium.com/features';
export const SUPPORT_EMAIL = 'support@vodium.com';

export const API_ORIGIN = 'https://teams-svc.vodium.com';

/* Used for Microsoft fulfillment APIs */
export const API_VERSION = '2018-08-31';

//to remove
export const PUBLISHER_ID = 'FourthCoffee';