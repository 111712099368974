import { msalInstance } from "..";
import { loginRequest } from "../services/authProvider";


const logout = () => {
  msalInstance.logoutRedirect()
  console.log("User logged out.")
};

const login = async () => {
  await msalInstance.handleRedirectPromise();
  await msalInstance.loginRedirect(loginRequest)
  console.log("User logged in.")
}

export {
  logout,
  login,
};